

import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
    Select,
    MenuItem,
    Grid,
    Button,
    Typography,
    TextField,
    Paper,
    Modal,
    Backdrop,
    Fade,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useAlert from './useAlert';

export default function App() {
    const { showAlert, alertComponent } = useAlert();

    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState(0);
    const [vendorId, setVendorId] = useState('Select Vendor');
    const [editItemId, setEditItemId] = useState(null);
    const [vendorCost, setVendorCost] = useState(0);
    const [currency, setCurrency] = useState('USD');
    const [quantity, setQuantity] = useState(1);
    const [allVendors, setAllVendors] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [searchText, setSearchText] = useState('');

    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

    const toggleCreateModal = () => {
        setName('');
        setDescription('');
        setPrice(0);
        setQuantity(1);
        setVendorCost(0);
        setVendorId('Select Vendor');
        setEditItemId(null);
        setIsOpen(true);
    };

    const toggleEditModal = (itemId) => {
        setEditItemId(itemId);
        setIsOpen(true);
        fetch(`https://invoiceapi.ahmed7.com/get/item/${itemId}`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setName(data.name);
                setDescription(data.description);
                setPrice(data.price);
                setCurrency(data.currency);
                setQuantity(data.quantity);
                setVendorCost(data.vendor_cost);
                if (data.vendor_id) {
                    setVendorId(data.vendor_id);
                } else {
                    setVendorId('Select Vendor');
                }
            })
            .catch((error) => {
                console.error('Error fetching item details for editing:', error);
            });
    };

    useEffect(() => {
        fetchItem();
        fetchVendor();
    }, []);

    const fetchItem = () => {
        fetch('https://invoiceapi.ahmed7.com/get/item', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setTableData(data);
            })
            .catch((error) => {
                console.error('Error fetching item data:', error);
            });
    };

    const fetchVendor = () => {
        fetch('https://invoiceapi.ahmed7.com/get/type/vendor', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setAllVendors(data);
            })
            .catch((error) => {
                console.error('Error fetching all vendor:', error);
            });
    };

    const createItem = () => {
        const itemData = {
            name: name,
            description: description,
            price: price,
            currency: currency,
            quantity: quantity,
            vendor_cost: vendorCost,
            vendor_id: vendorId,
        };
        fetch('https://invoiceapi.ahmed7.com/create/item', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            },
            body: JSON.stringify(itemData),
        })
            .then((response) => {
                if (response.ok) {
                    setIsOpen(false);
                    fetchItem();
                } else {
                    showAlert('Failed to create item');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const editItem = () => {
        const itemData = {
            name: name,
            description: description,
            price: price,
            vendor_cost: vendorCost,
            currency: currency,
            quantity: quantity,
            vendor_id: vendorId,
        };
        fetch(`https://invoiceapi.ahmed7.com/edit/item/${editItemId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            },
            body: JSON.stringify(itemData),
        })
            .then((response) => {
                if (response.ok) {
                    setIsOpen(false);
                    fetchItem();
                } else {
                    showAlert('Failed to edit item');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleDeleteClick = (itemId) => {
        setEditItemId(itemId);
        setDeleteConfirmationOpen(true);
    };

    const handleDeleteConfirmation = async () => {
        fetch(`https://invoiceapi.ahmed7.com/delete/item/${editItemId}`, {
            method: 'DELETE',
headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            },

        })
            .then((response) => response.text())
            .then((data) => {
                fetchItem();
            })
            .catch((error) => console.error('Error deleting bill_to:', error));
        setDeleteConfirmationOpen(false);
    };

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'description', headerName: 'Description', flex: 1 },
        { field: 'price', headerName: 'Price', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <div>
                    <Button onClick={() => toggleEditModal(params.row.id)}>
                        <EditIcon />
                    </Button>
                    <Button onClick={() => handleDeleteClick(params.row.id)}>
                        <DeleteIcon />
                    </Button>
                </div>
            ),
        },
    ];

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    return (
        <div className='item'>
            <h1>Item Details</h1>
            {alertComponent}
            <Grid container alignItems='center' justifyContent='space-between'>
                <Grid item>
                    <Button variant='contained' onClick={toggleCreateModal}>
                        Create Item
                    </Button>
                </Grid>
                <Grid item>
                    <TextField type='text' value={searchText} onChange={handleSearch} placeholder='Search...' />
                </Grid>
            </Grid>
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid rows={tableData.filter((row) => row.name.toLowerCase().includes(searchText.toLowerCase()))} columns={columns} pageSize={5} />
            </div>

            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>{editItemId ? 'Edit Item' : 'Create Item'}</DialogTitle>
                <DialogContent>
                    <TextField fullWidth={true} label='Name' value={name} onChange={(e) => setName(e.target.value)} />
                    <TextField fullWidth={true} label='Description' value={description} onChange={(e) => setDescription(e.target.value)} />
                    <TextField type='number' label='Price' value={price} onChange={(e) => setPrice(e.target.value)} />
                    <Select value={currency} onChange={(e) => setCurrency(e.target.value)}>
                        <MenuItem value='USD'>USD</MenuItem>
                        <MenuItem value='CAD'>CAD</MenuItem>
                    </Select>
                    <TextField type='number' label='Quantity' value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                    <Select value={vendorId} onChange={(e) => setVendorId(e.target.value)}>
                        <MenuItem value='Select Vendor'>Select Vendor</MenuItem>
                        {allVendors.map((vendor) => (
                            <MenuItem key={vendor.id} value={vendor.id}>
                                {vendor.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField type='number' label='Vendor Cost' value={vendorCost} onChange={(e) => setVendorCost(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={() => setIsOpen(false)}>
                        Close
                    </Button>
                    <Button color='primary' onClick={editItemId ? editItem : createItem}>
                        {editItemId ? 'Edit' : 'Create'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
                <DialogTitle>Delete Item</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to delete this item?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteConfirmationOpen(false)} color='secondary'>
                        No
                    </Button>
                    <Button onClick={handleDeleteConfirmation} color='primary'>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}



import { useEffect, useState } from 'react';
import { Grid, Button, Typography, TextField, Paper, Modal, Backdrop, Fade, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';


export default function BillTo({  }) {
    let params = useParams();

    const item = params.type

    var title = 'Bill To';
    if (item === 'bill_to') {
        title = 'Bill To';
    } else if (item === 'ship_from') {
        title = 'Ship From';
    } else if (item === 'ship_to') {
        title = 'Ship To';
    } else if (item === 'vendor') {
        title = 'Vendor';
    }

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [createMode, setCreateMode] = useState(true);
    const [editBillToId, setEditBillToId] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        address1: '',
        address2: '',
    });

    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    useEffect(() => {
        fetchBillTo();
    }, []);
    useEffect(() => {
        setTableData([]);
        fetchBillTo();
    }, [item]);
    const fetchBillTo = () => {
        fetch(`https://invoiceapi.ahmed7.com/get/type/${item}`, {
  headers: {
    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
  }
})
            .then((response) => response.json())
            .then((data) => {
                setTableData(data);
            })
            .catch((error) => {
                console.error('Error fetching bill_to data:', error);
            });
    };

    const handleDeleteClick = (billToId) => {
        setDeleteId(billToId);
        setDeleteConfirmationOpen(true);
    };

    const handleConfirmDelete = () => {
        const fullPath = window.location.pathname;
        const pathParts = fullPath.split('/');
        const item = pathParts[pathParts.length - 1];

        fetch(`https://invoiceapi.ahmed7.com/delete/type/${item}/${deleteId}`, {
            method: 'DELETE',
headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            },

        })
            .then((response) => response.text())
            .then((data) => {
                setTableData([]);
                fetchBillTo();
                setDeleteConfirmationOpen(false);
            })
            .catch((error) => console.error('Error deleting bill_to:', error));
    };

    const handleEditClick = (billToId) => {
        setEditBillToId(billToId);
        setCreateMode(false);
        setShowCreateModal(true);
        getBillToDetails(billToId);
    };

    const handleCreateClick = () => {
        setCreateMode(true);
        setShowCreateModal(true);

        setFormData({
            name: '',
            address1: '',
            address2: '',
        });
    };

    const getBillToDetails = (billToId) => {
        fetch(`https://invoiceapi.ahmed7.com/get/type/${item}/${billToId}`, {
  headers: {
    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
  }
})
            .then((response) => response.json())
            .then((data) => {
                setEditBillToId(data.id);
                setFormData(data);
            })
            .catch((error) => {
                console.error('Error fetching bill_to data:', error);
            });
    };

    const editBillTo = () => {
        const apiUrl = createMode ? `https://invoiceapi.ahmed7.com/create/type/${item}` : `https://invoiceapi.ahmed7.com/edit/type/${item}/${editBillToId}`;

        fetch(apiUrl, {
            method: createMode ? 'POST' : 'PUT',
            headers: {
                'Content-Type': 'application/json',               
 'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.text())
            .then((data) => {
                setShowCreateModal(false);
                setTableData([]);
                fetchBillTo();
            })
            .catch((error) => console.error('Error updating/creating bill_to:', error));
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'address1', headerName: 'Address 1', width: 200 },
        { field: 'address2', headerName: 'Address 2', width: 200 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <div>
                    <Button onClick={() => handleEditClick(params.row.id)}>
                        <EditIcon />
                    </Button>
                    <Button onClick={() => handleDeleteClick(params.row.id)}>
                        <DeleteIcon />
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div className='bill_to'>
            <h2>{title}</h2>
            <Grid container alignItems='center' justifyContent='space-between'>
                <Grid item>
                    <Button variant='contained' onClick={handleCreateClick}>
                        Create {title}
                    </Button>
                </Grid>
                <Grid item>
                    <TextField type='text' value={searchText} onChange={handleSearch} placeholder='Search...' />
                </Grid>
            </Grid>
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid rows={tableData.filter((row) => row.name.toLowerCase().includes(searchText.toLowerCase()))} columns={columns} />
            </div>
            <Dialog fullWidth={true} maxWidth={'sm'} open={showCreateModal} onClose={() => setShowCreateModal(false)}>
                <DialogTitle>
                    {createMode ? 'Create' : 'Edit'} {title}
                </DialogTitle>
                <DialogContent>
                    <Grid container direction='column' alignItems='center' justifyContent='center' spacing={2} p={4}>
                        <TextField fullWidth={true} label='Name' value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                        <TextField
                            fullWidth={true}
                            label='Address 1'
                            value={formData.address1}
                            onChange={(e) => setFormData({ ...formData, address1: e.target.value })}
                        />
                        <TextField
                            fullWidth={true}
                            label='Address 2'
                            value={formData.address2}
                            onChange={(e) => setFormData({ ...formData, address2: e.target.value })}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowCreateModal(false)}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={editBillTo}>
                        {createMode ? 'Create' : 'Update'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <Typography variant='body1'>Are you sure you want to delete this item?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteConfirmationOpen(false)}>No</Button>
                    <Button onClick={handleConfirmDelete} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}



import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextareaAutosize,
    TableFooter,
    TextField,
    FormControl,
    InputLabel,
    FormLabel,
    InputAdornment, IconButton
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { usePDF } from 'react-to-pdf';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { esAR } from 'rsuite/esm/locales';
import useAlert from './useAlert';
import Autocomplete from '@mui/material/Autocomplete';
import { useParams } from 'react-router-dom';
export default function Invoice({ create = true }) {
    let params = useParams();

    const entity = params.entity
    const type = params.type
    const entity_edit_id = params.entity_edit_id

    if (entity_edit_id && entity_edit_id != null) {
        create = false
    }

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [invoice, setInvoice] = useState([]);
    const [filters, setFilters] = useState({
        billTo: '',
        shipFrom: '',
        shipTo: '',
        vendor: '',
        dateFilter: 'all',
    });
    const [deleteInvoiceId, setDeleteInvoiceId] = useState('');
    const [searchText, setSearchText] = useState('');
    const [all_bill_to, set_all_bill_to] = useState([]);
    const [all_ship_from, set_all_ship_from] = useState([]);
    const [all_ship_to, set_all_ship_to] = useState([]);
    const [all_vendors, set_all_vendors] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [isOpenDelete, setOpenDelete] = useState(false);
    const { showAlert, alertComponent } = useAlert();
    // const type = (location.href.substring(location.href.lastIndexOf('/') + 1));


    useEffect(() => {
        setInvoice([])
        fetchInvoice();
    }, [type]);

    useEffect(() => {
        fetchInvoice();
        fetchVendor();
        fetchBillTo();
        fetchShipFrom();
        fetchShipTo();
    }, []);
    useEffect(() => {
        filterInvoice();
    }, [startDate, endDate, searchText]);


    if (!create) {
        return <CreateInvoice create={create} entity={entity} type={type} entity_edit_id={entity_edit_id}></CreateInvoice>;
    }




    var columns;
    if (entity == 'invoice') {
        columns = [
            { field: 'id', headerName: 'Invoice ID', width: 150 },
            { field: 'date', headerName: 'Date', width: 120 },
            { field: 'bill_to', headerName: 'Bill To', width: 200, valueGetter: (params) => params && params.name },
            { field: 'ship_to', headerName: 'Ship To', width: 200, valueGetter: (params) => params && params.name },
            { field: 'ship_from', headerName: 'Ship From', width: 200, valueGetter: (params) => params && params.name },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 130,
                sortable: false,
                renderCell: (params) => (
                    <div>
                        <Button
                            onClick={() => {
                                setDeleteInvoiceId(params.row.id);
                                onOpenDelete();
                            }}
                        >
                            <DeleteIcon />
                        </Button>
                        <Button href={`/document/${entity}/${type}/${params.row.id}`} >
                            <EditIcon />
                        </Button>
                    </div>
                ),
            },
        ];
    } else if (entity == 'shipment') {
        columns = [
            { field: 'id', headerName: 'Invoice ID', width: 150 },
            { field: 'arrival_date', headerName: 'Arrival Date', width: 120 },
            { field: 'issued_to', headerName: 'Issued To', width: 200, valueGetter: (params) => params && params.name },
            { field: 'ship_to', headerName: 'Ship To', width: 200, valueGetter: (params) => params && params.name },
            { field: 'ship_from', headerName: 'Ship From', width: 200, valueGetter: (params) => params && params.name },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 130,
                sortable: false,
                renderCell: (params) => (
                    <div>
                        <Button
                            onClick={() => {
                                setDeleteInvoiceId(params.row.id);
                                onOpenDelete();
                            }}
                        >
                            <DeleteIcon />
                        </Button>
                        <Button href={`/${entity}/${params.row.id}`} >
                            <EditIcon />
                        </Button>
                    </div>
                ),
            },
        ];
    } else if (entity == 'purchase_order') {
        columns = [
            { field: 'id', headerName: 'Invoice ID', width: 150 },
            { field: 'date', headerName: 'Date', width: 120 },
            { field: 'due_date', headerName: 'Due Date', width: 120 },
            { field: 'purchase_order_status', headerName: 'Status', width: 200 },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 130,
                sortable: false,
                renderCell: (params) => (
                    <div>
                        <Button
                            onClick={() => {
                                setDeleteInvoiceId(params.row.id);
                                onOpenDelete();
                            }}
                        >
                            <DeleteIcon />
                        </Button>
                        <Button href={`/${entity}/${params.row.id}`} >
                            <EditIcon />
                        </Button>
                    </div>
                ),
            },
        ];
    } else if (entity == 'daily_account') {
        columns = [
            { field: 'id', headerName: 'Invoice ID', width: 150 },
            { field: 'date', headerName: 'Date', width: 120 },
            { field: 'vendor', headerName: 'Vendor', width: 200, valueGetter: (params) => params && params.name },
            { field: 'bill_to', headerName: 'Bill To', width: 200, valueGetter: (params) => params && params.name },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 130,
                sortable: false,
                renderCell: (params) => (
                    <div>
                        <Button
                            onClick={() => {
                                setDeleteInvoiceId(params.row.id);
                                onOpenDelete();
                            }}
                        >
                            <DeleteIcon />
                        </Button>
                        <Button href={`/${entity}/${params.row.id}`} >
                            <EditIcon />
                        </Button>
                    </div>
                ),
            },
        ];
    }



    function onOpen() {
        setOpen(true);
    }
    function onClose() {
        setOpen(false);
    }

    function onOpenDelete() {
        setOpenDelete(true);
    }
    function onCloseDelete() {
        setOpenDelete(false);
    }

    async function fetchInvoice() {
        fetch(`https://invoiceapi.ahmed7.com/main/${entity}/get/${type}`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setInvoice(data.reverse());
            })
            .catch((error) => {
                console.error('Error fetching invoice:', error);
            });
    }

    function deleteInvoice() {
        fetch(`https://invoiceapi.ahmed7.com/main/${entity}/${deleteInvoiceId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            },

        })
            .then((response) => {
                if (response.ok) {
                    showAlert('Invoice deleted successfully');
                    fetchInvoice();
                } else {
                    showAlert('Failed to delete invoice');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    async function fetchVendor() {
        fetch('https://invoiceapi.ahmed7.com/get/type/vendor', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                set_all_vendors(data);
            })
            .catch((error) => {
                console.error('Error fetching all vendor:', error);
            });
    }

    async function fetchBillTo() {
        fetch('https://invoiceapi.ahmed7.com/get/type/bill_to', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                set_all_bill_to(data);
            })
            .catch((error) => {
                console.error('Error fetching invoice data:', error);
            });
    }
    function fetchShipFrom() {
        fetch('https://invoiceapi.ahmed7.com/get/type/ship_from', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                set_all_ship_from(data);
            })
            .catch((error) => {
                console.error('Error fetching ship_from data:', error);
            });
    }
    function fetchShipTo() {
        fetch('https://invoiceapi.ahmed7.com/get/type/ship_to', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                set_all_ship_to(data);
            })
            .catch((error) => {
                console.error('Error fetching ship_to data:', error);
            });
    }

    function filterInvoice() {
        const { billTo, shipFrom, shipTo, vendor, dateFilter } = filters;
        let filteredInvoice
        filteredInvoice = invoice.filter((row) =>
            Object.values(row).some(
                (value) =>
                    value && value.toString().toLowerCase().includes(searchText)
            )
        );
        filteredInvoice = filteredInvoice.filter((invoice) => {
            return (
                (billTo === '' ||
                    (invoice.bill_to !== undefined &&
                        invoice.bill_to.name !== undefined &&
                        invoice.bill_to.name.toLowerCase().includes(billTo.toLowerCase()))) &&
                (shipFrom === '' ||
                    (invoice.ship_from !== undefined &&
                        invoice.ship_from !== null &&
                        invoice.ship_from.name !== undefined &&
                        invoice.ship_from.name.toLowerCase().includes(shipFrom.toLowerCase()))) &&
                (shipTo === '' ||
                    (invoice.ship_to !== undefined &&
                        invoice.ship_to.name !== undefined &&
                        invoice.ship_to.name.toLowerCase().includes(shipTo.toLowerCase()))) &&
                (vendor === '' ||
                    (invoice.vendor !== undefined && invoice.vendor.name !== undefined && invoice.vendor.name.toLowerCase().includes(vendor.toLowerCase())))
            );
        });

        if (startDate != null && endDate != null) {
            filteredInvoice = filteredInvoice.filter((invoice) => {
                const invoiceDate = new Date(invoice.date);
                let startDate2 = startDate;
                startDate2.setDate(startDate.getDate() - 1);
                return startDate2 <= invoiceDate && endDate >= invoiceDate;
            });
        }

        return filteredInvoice;
    }
    function fetchInvoiceHandler() {
        fetchInvoice();
    }

    const filteredInvoice = filterInvoice();

    return (
        <Box className='invoice'>
            {alertComponent}

            <div style={{ display: "grid", gridAutoFlow: "column", alignItems: "center", gridGap: "10px", width: 'max-content' }}>
                <h1>{type}</h1>
                <Button variant='contained' onClick={onOpen} style={{ maxHeight: "40px" }}>
                    Create {type}
                </Button>
                <CreateSummaryContainer data3={filteredInvoice}></CreateSummaryContainer>

            </div>

            <Dialog maxWidth='xl' open={isOpen} onClose={onClose}>
                <DialogTitle>Create {type}</DialogTitle>
                <DialogContent>
                    <CreateInvoice type={type} entity={entity} fetchInvoiceHandler={fetchInvoiceHandler} edit={!create} onCloseParent={onClose} />
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>

            <Dialog maxWidth='xl' open={isOpenDelete} onClose={onCloseDelete}>
                <DialogTitle>Delete {type}</DialogTitle>
                <DialogContent>Are you sure you want to Delete this {type}?</DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={onCloseDelete}>
                        Cancel
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => {
                            deleteInvoice();
                            onCloseDelete();
                        }}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <h2>Filters</h2>
            <div style={{ maxWidth: 'calc(100vw - 250px)' }}>
                <Grid container spacing={1}>
                    <Grid item xl={3} xs={12} md={6} lg={4} xs={12} md={10}>
                        <TextField
                            variant="outlined"
                            label="Search everything"
                            value={searchText}
                            onChange={(e) => { setSearchText(e.target.value) }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>


                    <Grid item xl={3} xs={12} md={6} lg={4} >
                        <TextField
                            label='Search: Bill To'
                            variant='outlined'
                            value={filters.billTo}
                            onChange={(e) => setFilters({ ...filters, billTo: e.target.value })}
                        />
                        <Select label='Bill To' variant='outlined' value={filters.billTo} onChange={(e) => setFilters({ ...filters, billTo: e.target.value })}>
                            <MenuItem value=''>All</MenuItem>
                            {all_bill_to.map((item, index) => (
                                <MenuItem key={index} value={item.name}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xl={3} xs={12} md={6} lg={4} >
                        <TextField
                            label='Search: Ship To'
                            variant='outlined'
                            value={filters.shipTo}
                            onChange={(e) => setFilters({ ...filters, shipTo: e.target.value })}
                        />
                        <Select label='Ship To' variant='outlined' value={filters.shipTo} onChange={(e) => setFilters({ ...filters, shipTo: e.target.value })}>
                            <MenuItem value=''>All</MenuItem>
                            {all_ship_to.map((item, index) => (
                                <MenuItem key={index} value={item.name}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xl={3} xs={12} md={6} lg={4} >
                        <TextField
                            label='Search: Ship From'
                            variant='outlined'
                            value={filters.shipFrom}
                            onChange={(e) => setFilters({ ...filters, shipFrom: e.target.value })}
                        />
                        <Select
                            label='Ship From'
                            variant='outlined'
                            value={filters.shipFrom}
                            onChange={(e) => setFilters({ ...filters, shipFrom: e.target.value })}
                        >
                            <MenuItem value=''>All</MenuItem>
                            {all_ship_from.map((item, index) => (
                                <MenuItem key={index} value={item.name}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xl={3} xs={12} md={6} lg={4} >
                        <TextField
                            label='Search: Vendor'
                            variant='outlined'
                            value={filters.vendor}
                            onChange={(e) => setFilters({ ...filters, vendor: e.target.value })}
                        />
                        <Select label='Vendor' variant='outlined' value={filters.vendor} onChange={(e) => setFilters({ ...filters, vendor: e.target.value })}>
                            <MenuItem value=''>All</MenuItem>
                            {all_vendors.map((item, index) => (
                                <MenuItem key={index} value={item.name}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <DatePicker
                            slotProps={{
                                textField: {
                                    helperText: 'MM/DD/YYYY',
                                },
                            }}
                            onChange={(newValue) => { setStartDate(new Date(newValue)); }} label="Show Invoice From" />
                        <DatePicker
                            slotProps={{
                                textField: {
                                    helperText: 'MM/DD/YYYY',
                                },
                            }}
                            onChange={(newValue) => { setEndDate(new Date(newValue)); }} label="Show Invoice Until" />


                    </Grid>
                </Grid>
            </div>
            <br />
            <DataGrid
                checkboxSelection
                // slotProps={{ toolbar: { printOptions: { disableToolbarButton: true }, csvOptions: { disableToolbarButton: true } } }}
                slots={{
                    toolbar: GridToolbar,
                }}
                disableSelectionOnClick
                disableRowSelectionOnClick
                rows={filteredInvoice} columns={columns} pageSize={5} />
        </Box>
    );
}

function CreateSummaryContainer({ data3 }) {
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant='contained' onClick={handleOpen}>
                Create Summary
            </Button>
            <Dialog maxWidth='xl' open={isOpen} onClose={handleClose}>
                <DialogTitle>Create Summary</DialogTitle>
                <DialogContent>
                    <CreateSummary data={data3} />
                </DialogContent>
                <DialogActions>{/* Add actions here if needed */}</DialogActions>
            </Dialog>
        </div>
    );
}

function CreateSummary({ data }) {
    const [currency, setCurrency] = useState('USD');
    const [header, setHeader] = useState('');
    const [footer, setFooter] = useState('');
    const { toPDF, targetRef } = usePDF({ filename: 'Summary.pdf', size: 'A4' });

    const totalPriceOfAllItems = 23;

    const columns = [
        { field: 'id', headerName: 'ID' },
        { field: 'date', headerName: 'Date' },
        { field: 'bill_to', headerName: 'Bill To', width: 200, valueGetter: (params) => params.row.bill_to && params.row.bill_to.name },
        { field: 'ship_to', headerName: 'Ship To', width: 200, valueGetter: (params) => params.row.ship_to && params.row.ship_to.name },
        { field: 'ship_from', headerName: 'Ship From', width: 200, valueGetter: (params) => params.row.ship_from && params.row.ship_from.name },
        { field: 'bl_number', headerName: 'BL Number', width: 200 },
        {
            field: 'total_price',
            headerName: 'Total Price',
            width: 150,
            valueGetter: (params) => {
                const items = params.row.all_items;
                if (items && items.length > 0) {
                    return items.reduce((total, item) => total + item.price * item.quantity, 0) + ' ' + currency;
                } else {
                    return 0;
                }
            },
        },
    ];

    const handleDownloadExcel = () => {
        fetch('https://invoiceapi.ahmed7.com/download_excel', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'invoices.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => console.error('Error:', error));
    };

    return (
        <div>
            <div className='create_summary'>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={1}>
                        <FormControl>
                            <InputLabel id='currency-label'>Currency:</InputLabel>
                            <Select labelId='currency-label' id='currency' value={currency} onChange={(e) => setCurrency(e.target.value)}>
                                <MenuItem value='USD'>USD</MenuItem>
                                <MenuItem value='CAD'>CAD</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField fullWidth rows={3} label='Header' variant='outlined' value={header} onChange={(e) => setHeader(e.target.value)} multiline />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField fullWidth rows={3} label='Footer' variant='outlined' value={footer} onChange={(e) => setFooter(e.target.value)} multiline />
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button variant='contained' onClick={handleDownloadExcel}>
                            Download Excel
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant='contained'
                            onClick={() => {
                                const summaryPrintElement = targetRef.current;
                                toPDF();
                            }}
                        >
                            Download PDF
                        </Button>
                    </Grid>
                </Grid>

                <div className='summary_print' ref={targetRef}>
                    <center>
                        <h2>{header}</h2>
                    </center>
                    <TableContainer component={Paper}>
                        <Table aria-label='material table'>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell key={column.field}>{column.headerName}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row) => (
                                    <TableRow key={row.id}>
                                        {columns.map((column) => (
                                            <TableCell key={column.field}>{column.valueGetter ? column.valueGetter({ row }) : row[column.field]}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <center>
                        <h2>{footer}</h2>
                    </center>
                </div>
            </div>
        </div>
    );
}

function CreateInvoice({ create = true, entity_edit_id = null, fetchInvoiceHandler = null, type, entity, onCloseParent }) {

    const [all_bill_to, set_all_bill_to] = useState([]);
    const [all_ship_from, set_all_ship_from] = useState([]);
    const [all_ship_to, set_all_ship_to] = useState([]);
    const [all_vendors, set_all_vendors] = useState([]);
    const [all_items, set_all_items] = useState();
    const [selected_items, set_selected_items] = useState([]);

    const [all_inputs, set_all_inputs] = useState([

    ]);

    const [formData, setFormData] = useState({});


    const [company_information, set_company_information] = useState('');
    const [bank_details, set_bank_details] = useState('');

    const [id, set_id] = useState(null);

    const [selectedItem, setSelectedItem] = useState('');





    useEffect(() => {
        fetchCompany()
        fetchVendor()
        fetchBillTo()
        fetchShipFrom()
        fetchShipTo()
        fetchItems()
    }, []);



    useEffect(() => {

        const updatedInputs = [
            { name: 'description', title: 'Description', input: 'text', size: 'big' },
        ]


        let entity_inputs


        if (entity_edit_id) {
            set_id(entity_edit_id)
        }

        let id_start
        const id_number = Math.floor(Math.random() * (9999999 - 1000000 + 1)) + 1000000


        if (entity === 'invoice') {
            id_start = `INV - `;
            if (entity_edit_id == null && id == null) {
                set_id(id_start + id_number.toString())
            }
            entity_inputs = [
                { name: 'id', title: 'Invoice ID', input: 'text', value: id, params: ['no_edit'] },
                {
                    name: 'type', title: 'Invoice Type', input: 'select', select_options: ['Invoice', 'First Quote', 'Final Quote'], value: type.toLowerCase()  // convert the entire string to lower case
                        .split('_')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')
                },
                { name: 'invoice_status', title: 'Invoice Status', input: 'select', select_options: ['Pending', 'Sent', 'Partial', 'Paid'] },
                { name: 'date', title: 'Date', input: 'date' },
                { name: 'due_date', title: 'Due Date', input: 'date' },
                { name: 'bill_to', title: 'Bill To', input: 'select', select_options: all_bill_to },
                { name: 'ship_from', title: 'Ship From', input: 'select', select_options: all_ship_from },
                { name: 'ship_to', title: 'Ship To', input: 'select', select_options: all_ship_to },
                { name: 'bl_number', title: 'BL Number', input: 'text' },
                { name: 'currency', title: 'Currency', input: 'select', select_options: ['CAD', "USD"] },
                { name: 'terms', title: 'Terms', input: 'text', size: 'big' },
                { name: 'extra_info', title: 'Extra Info', input: 'text', size: 'big' },

            ]


        } else if (entity == 'shipment') {
            id_start = `Shipment - `;
            if (entity_edit_id == null && id == null) {
                set_id(id_start + id_number.toString())
            }
            entity_inputs = [
                { name: 'id', title: 'Shipment ID', input: 'text', value: id, params: ['no_edit'] },
                { name: 'type', title: 'Shipment Type', input: 'select', select_options: ['Delivery Order', 'Pre Alert'] },
                { name: 'shipment_status', title: 'Shipment Status', input: 'select', select_options: ['Air', 'Sea', 'Rail', 'Warehouse', 'Customs Released'] },
                { name: 'container_number', title: 'Container Number', input: 'text' },
                { name: 'shipping_details', title: 'Shipping Details', input: 'text', size: 'big' },
                { name: 'arrival_date', title: 'Arrival Date', input: 'date' },
                { name: 'due_date', title: 'Due Date', input: 'date' },
                { name: 'bl_number', title: 'BL Number', input: 'text' },
                { name: 'issued_to', title: 'Issued To', input: 'select', select_options: all_bill_to },
                { name: 'ship_from', title: 'Ship From', input: 'select', select_options: all_ship_from },
                { name: 'ship_to', title: 'Ship To', input: 'select', select_options: all_ship_to },
                { name: 'terms', title: 'Terms', input: 'text', size: 'big' },
                { name: 'currency', title: 'Currency', input: 'select', select_options: ['CAD', "USD"] },

            ]
        }


        else if (entity === 'purchase_order') {
            id_start = `PO - `;
            if (entity_edit_id == null && id == null) {
                set_id(id_start + id_number.toString())
            }
            entity_inputs = [
                { name: 'id', title: 'Purchase Order ID', input: 'text', value: id, params: ['no_edit'] },
                { name: 'date', title: 'Date', input: 'date' },
                { name: 'due_date', title: 'Due Date', input: 'date' },
                { name: 'purchase_order_status', title: 'Status', input: 'select', select_options: ['Pending', 'Sent', 'Partial', 'Paid'] },

            ]
        }
        else if (entity === 'daily_account') {
            id_start = `DA - `;
            if (entity_edit_id == null && id == null) {
                set_id(id_start + id_number.toString())
            }
            entity_inputs = [
                { name: 'id', title: 'Daily Account ID', input: 'text', value: id, params: ['no_edit'] },
                {
                    name: 'type', title: 'Invoice Type', input: 'select', select_options: ['Daily Account', 'Daily Receivable'], value: type.toLowerCase()  // convert the entire string to lower case
                        .split('_')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')
                },
                { name: 'date', title: 'Date', input: 'date' },
                { name: 'vendor', title: 'Vendor', input: 'select', select_options: all_vendors },
                { name: 'bill_to', title: 'Bill To', input: 'select', select_options: all_bill_to },
                { name: 'currency', title: 'Currency', input: 'select', select_options: ['CAD', "USD"] },

            ]
        }


        const new_inputs = [...entity_inputs, ...updatedInputs]
        set_all_inputs(new_inputs)



    }, [all_bill_to, all_ship_to, all_ship_from, all_vendors]);

    useEffect(() => {
        setFormData(Object.fromEntries(all_inputs.map((input) => [input.name, input.value ?? null])))
        if (!create) {
            get_invoice_details();
        }
    }, [all_inputs]);
    const { showAlert, alertComponent } = useAlert();

    function getFormattedDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    async function get_invoice_details() {
        const response = await fetch(`https://invoiceapi.ahmed7.com/main/${entity}/` + entity_edit_id, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        });
        const data = await response.json();
        const all_items_ = data['all_items'];
        set_selected_items(all_items_);

        const formDataObject = {};
        all_inputs.forEach((input) => {
            if (input.name === 'bill_to' || input.name === 'ship_to' || input.name === 'ship_from' || input.name === 'vendor') {
                formDataObject[input.name] = data[input.name];
                // formDataObject[input.name] = JSON.stringify(data[input.name]);
            } else {
                formDataObject[input.name] = data[input.name];
            }
        });
        formDataObject['id'] = formData['id']
        set_id(formData['id'])
        setFormData(formDataObject);
    }

    const edit_invoice_fields = (index, field, value) => {
        const updatedItems = [...selected_items];
        updatedItems[index][field] = value;
        set_selected_items(updatedItems);
    };

    const add_new_invoice_item = (selectedItemValue) => {
        if (selectedItemValue == 'blank') {
            set_selected_items([...selected_items, { id: 0, name: '', description: '', price: 0, quantity: 1 }]);
            return;
        }
        const selectedItemValue2 = JSON.parse(selectedItemValue);
        const selectedItem = all_items.find((item) => item.id === selectedItemValue2.id);
        if (selectedItem) {
            const selectedItemJson = JSON.stringify(selectedItem);
            set_selected_items([...selected_items, { ...selectedItem, quantity: 1 }]);
        } else {
            showAlert('Error item not found in the list');
        }
    };
    const removeItem = (index) => {
        const updatedItems = [...selected_items];
        updatedItems.splice(index, 1);
        set_selected_items(updatedItems);
    };

    function fetchVendor() {
        fetch('https://invoiceapi.ahmed7.com/get/type/vendor', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                set_all_vendors(data);
            })
            .catch((error) => {
                console.error('Error fetching all vendor:', error);
            });
    }

    async function fetchCompany() {
        fetch('https://invoiceapi.ahmed7.com/get/company', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                set_bank_details(data.bank_details)
                set_company_information(
                    <div>
                        <h1>{data.name}</h1>
                        <div>{data.address1}</div>
                        <div>{data.address2}</div>
                        <div>{data.address3}</div>
                        <div>Tel: {data.tel}</div>
                        <div>Fax: {data.fax}</div>
                        <div>GST Registration #: {data.gst}</div>
                    </div>
                );
            })
            .catch((error) => {
                console.error('Error fetching company data:', error);
            });
    }
    async function fetchBillTo() {
        fetch('https://invoiceapi.ahmed7.com/get/type/bill_to', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                set_all_bill_to(data);
            })
            .catch((error) => {
                console.error('Error fetching invoice data:', error);
            });
    }
    async function fetchShipFrom() {
        fetch('https://invoiceapi.ahmed7.com/get/type/ship_from', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                set_all_ship_from(data);
            })
            .catch((error) => {
                console.error('Error fetching ship_from data:', error);
            });
    }
    async function fetchShipTo() {
        fetch('https://invoiceapi.ahmed7.com/get/type/ship_to', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                set_all_ship_to(data);
            })
            .catch((error) => {
                console.error('Error fetching ship_to data:', error);
            });
    }
    async function fetchItems() {
        fetch('https://invoiceapi.ahmed7.com/get/item', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                set_all_items(data);
            })
            .catch((error) => {
                console.error('Error fetching items data:', error);
            });
    }

    async function createInvoice() {
        let formDataWithIDs = { ...formData };
        let method;
        if (create) {
            method = 'POST';
            formDataWithIDs.id = id;
        } else {
            method = 'PUT';
            formDataWithIDs.id = entity_edit_id;
        }
        formDataWithIDs.all_items = JSON.stringify(selected_items);

        try {
            const response = await fetch(`https://invoiceapi.ahmed7.com/main/${entity}`, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(formDataWithIDs),
            });

            if (!response.ok) {
                const errorResponse = await response.json();
                showAlert(errorResponse.error);
                console.error('Error:', errorResponse.error);
                return;
            }

            const successResponse = await response.json();
            showAlert(successResponse.message);

            if (create) {
                window.location.href = `/document/${entity}/${type}/` + encodeURI(formDataWithIDs.id)
                fetchInvoiceHandler();
            }

        } catch (error) {
            showAlert('Failed to create invoice ' + error, 'error');
            console.error('Error:', error);
        }

    }

    const { toPDF, targetRef } = usePDF({ filename: id + ' ' + getFormattedDate() + '.pdf' });

    const [isOpen, setOpen] = useState(false);
    function onOpen() {
        setOpen(true);
    }
    function onClose() {
        setOpen(false);
    }

    const handleInputChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };
    return (
        <div className='invoice'>
            <h1>{type} Details</h1>
            {alertComponent}
            <div>
                <Grid container spacing={1} fullWidth>
                    {all_inputs.map((input) => (
                        <Grid item xs={12} sm={input.size == 'big' ? 8 : 4} key={input.name} fullWidth>
                            {input.input === 'select' ? (
                                <FormControl fullWidth>

                                    <InputLabel id={`${input.name}-select-label`} shrink={true}>
                                        {input.title}
                                    </InputLabel>
                                    <Select
                                        labelId={`${input.name}-select-label`}
                                        disabled={input.params && (input.params.includes('readonly') || (input.params.includes('no_edit') && !create))} multiline={input.size == 'big'}
                                        id={`${input.name}-select`}
                                        value={formData[input.name] != null ? (typeof formData[input.name] === 'object' ? JSON.stringify(formData[input.name]) : formData[input.name]) : ""}
                                        onChange={(e) => handleInputChange(input.name, e.target.value)}
                                    >
                                        {input.select_options.map((option, index) => {
                                            let parsedOption;
                                            try {
                                                parsedOption = typeof option === 'string' ? JSON.parse(option) : option;
                                                return (
                                                    <MenuItem key={option.id ?? index} value={JSON.stringify(option)}>
                                                        {option.name}
                                                    </MenuItem>
                                                );
                                            } catch (error) {
                                                parsedOption = option;
                                                return (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                );
                                            }
                                        })}
                                    </Select>

                                </FormControl>
                            ) : (
                                <div>
                                    <TextField
                                        id={`${input.name}-textfield`}
                                        label={input.title}
                                        fullWidth
                                        type={input.input}
                                        disabled={input.params && (input.params.includes('readonly') || (input.params.includes('no_edit') && !create))} multiline={input.size == 'big'}
                                        rows={input.size == 'big' ? 3 : 1}
                                        value={formData[input.name]}
                                        defaultValue={input.value}
                                        onChange={(e) => handleInputChange(input.name, e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    />
                                </div>

                            )}

                        </Grid>
                    ))}
                </Grid>
                <Grid item xs={12} fullWidth>
                    <TableContainer>
                        <Table id='invoice-table' aria-label='invoice table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Item</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Total Price</TableCell>
                                    <TableCell>Vendor</TableCell>
                                    <TableCell>Vendor Cost</TableCell>
                                    <TableCell>Profit</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selected_items &&
                                    selected_items.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <TextField type='text' value={item.name} onChange={(e) => edit_invoice_fields(index, 'name', e.target.value)} />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type='text'
                                                    value={item.description}
                                                    onChange={(e) => edit_invoice_fields(index, 'description', e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type='number'
                                                    value={item.price}
                                                    onChange={(e) => edit_invoice_fields(index, 'price', parseFloat(e.target.value))}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type='number'
                                                    value={item.quantity}
                                                    onChange={(e) => edit_invoice_fields(index, 'quantity', parseFloat(e.target.value))}
                                                />
                                            </TableCell>
                                            <TableCell>{(item.price * item.quantity).toFixed(2)}</TableCell>
                                            <TableCell>
                                                <FormControl>
                                                    <Select
                                                        value={item.vendor_id}
                                                        onChange={(e) => edit_invoice_fields(index, 'vendor_id', parseFloat(e.target.value))}
                                                    >
                                                        <MenuItem value={'Select Vendor'}>No Vendor</MenuItem>
                                                        {all_vendors.map(function (vendor_) {
                                                            return (
                                                                <MenuItem key={vendor_.id} value={vendor_.id}>
                                                                    {vendor_.name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type='number'
                                                    value={item.vendor_cost}
                                                    onChange={(e) => edit_invoice_fields(index, 'vendor_cost', parseFloat(e.target.value))}
                                                />
                                            </TableCell>
                                            <TableCell>{item.price - item.vendor_cost}</TableCell>
                                            <TableCell>
                                                <Button variant='contained' onClick={() => removeItem(index)}>
                                                    <DeleteIcon></DeleteIcon>
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell>
                                        <strong>Total Price of all items: </strong>
                                    </TableCell>
                                    <TableCell>
                                        {selected_items.reduce((acc, item) => acc + item.price * item.quantity, 0)} {formData['currency']}
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <br />
                    <Grid container spacing={1} style={{ alignItems: 'center' }} gap={2}>
                        <Button variant='contained' onClick={() => add_new_invoice_item('blank')}>
                            Add Item
                        </Button>
                        <FormControl style={{ minWidth: 300 }}>
                            <InputLabel id='select-item-select-label'>Add from exisitng items</InputLabel>
                            <Select
                                labelId='select-item-select-label'
                                value={selectedItem}
                                placeholder='Select Item'
                                onChange={(e) => {
                                    add_new_invoice_item(e.target.value);
                                    setSelectedItem('Add from exisitng items');
                                }}
                            >
                                <MenuItem key={'Add from exisitng items'} value='Add from exisitng items'>
                                    Add from exisitng items
                                </MenuItem>
                                {all_items &&
                                    all_items.map(function (item) {
                                        return (
                                            <MenuItem key={JSON.stringify(item)} value={JSON.stringify(item)}>
                                                {item.name} - {item.price} Price
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>

            <DialogActions>
                <Button variant='contained' color='error'  onClick={() => {   {create ? onCloseParent() : window.location.href = `/document/${entity}/${type}/`} }}>{create ? "Create" : "Go Back"}</Button>
                {entity == 'invoice' && !create && (<Button variant='contained' onClick={onOpen}>  View {type}
                </Button>)}
                <Button variant='contained' onClick={createInvoice}>
                    {create ? 'Create' : 'Edit'} {type}
                </Button>


            </DialogActions>
            <Dialog maxWidth='xl' open={isOpen} onClose={onClose}>
                <DialogTitle>View {type}</DialogTitle>
                <DialogContent>
                    <Create_invoice_viewer
                        create={create}
                        entity_edit_id={entity_edit_id}
                        targetRef={targetRef}
                        company_information={company_information}
                        bank_details={bank_details}
                        selected_items={selected_items}
                        formData={formData}
                        entity={entity}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={onClose}>
                        Close
                    </Button>
                    <Button variant='contained' onClick={() => { toPDF(); createInvoice() }}>
                        Download {type}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


function Create_invoice_viewer({ targetRef, company_information, bank_details, selected_items, formData, entity, create, entity_edit_id }) {

    let bill_to_info
    let issued_to_info
    let ship_from_info
    let ship_to_info
    try {


        bill_to_info = (formData['bill_to'])

    }
    catch {

    }
    try {


        issued_to_info = (formData['issued_to'])

    }
    catch {

    }
    try {


        ship_from_info = (formData['ship_from'])

    }
    catch {

    }
    try {


        ship_to_info = (formData['ship_to'])

    }
    catch {

    }

    if (entity == 'invoice') {

        bill_to_info = (<div className='bill_to_information'>
            <h3>Bill To</h3>
            {
                bill_to_info && (
                    <>
                        <div>{bill_to_info.name}</div>
                        <div>{bill_to_info.address1}</div>
                        <div>{bill_to_info.address2}</div>
                    </>
                )
            }


        </div>)

    }
    else if (entity == 'shipment') {



        issued_to_info = (<div className='bill_to_information'>
            <h3>Issued To</h3>
            {
                issued_to_info && (
                    <>
                        <div>{issued_to_info.name}</div>
                        <div>{issued_to_info.address1}</div>
                        <div>{issued_to_info.address2}</div>
                    </>
                )
            }

        </div>)


    }

    ship_from_info = (<div className='ship_from_information'>
        <h3>Ship From</h3>
        {
            ship_from_info && (
                <>
                    <div>{ship_from_info.name}</div>
                    <div>{ship_from_info.address1}</div>
                    <div>{ship_from_info.address2}</div>
                </>
            )
        }
    </div>)


    ship_to_info = (<div className='ship_to_information'>
        <h3>Ship To</h3>
        {
            ship_to_info && (
                <>
                    <div>{ship_to_info.name}</div>
                    <div>{ship_to_info.address1}</div>
                    <div>{ship_to_info.address2}</div>
                </>
            )
        }

    </div>)


    const totalPrice = selected_items.reduce((acc, item) => acc + item.price * item.quantity, 0);

    return (
        <div className='invoice_viewer_container'>
            <div ref={targetRef} className='invoice_viewer' id='invoice_viewer'>
                <div className='logo'></div>
                <h4>{formData['type']}</h4>
                <div className='first_section'>
                    <div className='company_information'>{company_information}</div>
                    <div className='invoice_information'>

                        <br />
                        <h4>{create ? formData['id'] : entity_edit_id}</h4>
                        <h5>Status: {formData['invoice_status'] || formData['shipment_status']}</h5>
                        <div>Date: {formData['date'] || formData['arrival_date']}</div>

                        <div>Due Date: {formData['due_date']}</div>
                        <div>B/L Number: {formData['bl_number']}</div>
                        <div>Description: {formData['description']}</div>
                        <div>Container Number: {formData['container_number']}</div>
                        <div className='extra_information'>
                            <div
                            >
                                {(formData['extra_info'] || formData['container_number'] || '').split('\n').map((item, index) => (
                                    <span key={index}>
                                        {item}
                                        <br />
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='second_section'>
                    {bill_to_info || issued_to_info}
                    {ship_from_info}

                    {ship_to_info}



                </div>
                <div className='forth_section'>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Item</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Total Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    selected_items.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.description}</TableCell>
                                            <TableCell>
                                                {formData['currency']} {item.price.toFixed(2)}
                                            </TableCell>
                                            <TableCell>{item.quantity}</TableCell>
                                            <TableCell>
                                                {formData['currency']} {(item.price * item.quantity).toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className='total_price_'>
                        Total Price: {totalPrice} {formData['currency']}
                    </div>
                </div>
                <div className='fifth_section'>
                    <h3>Bank Details</h3>
                    <div
                    >  {(bank_details || '').split('\n').map((item, index) => (
                        <span key={index}>
                            {item}
                            <br />
                        </span>
                    ))}
                    </div>
                </div>
                <div className='sixth_section'>
                    <br />
                    <div className='terms_and_conditions'>
                        <strong>Terms & Conditions: </strong>
                        <span

                        >
                            {(formData['terms'] || '').split('\n').map((item, index) => (
                                <span key={index}>
                                    {item}
                                    <br />
                                </span>
                            ))}
                        </span>
                    </div>
                    <div className='name'>Ahmed Mukit</div>
                    <div className='nsf'>All NSF Charges $25.00</div>
                    <br />
                    <center>THANK YOU FOR SHIPPING THROUGH MIANZ WE APPRECIATE YOUR BUSINESS</center>

                </div>
            </div>
        </div>
    );
}

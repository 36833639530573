import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, Grid, Paper } from '@mui/material';
import useAlert from './useAlert';

export default function Company() {
    const { showAlert, alertComponent } = useAlert();

    const [companyData, setCompanyData] = useState({
        name: '',
        address1: '',
        address2: '',
        tel: '',
        fax: '',
        gst: '',
        bank_details: '',
    });

    useEffect(() => {
        fetchCompany();
    }, []);

    function fetchCompany() {
        fetch('https://invoiceapi.ahmed7.com/get/company', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setCompanyData(data);
            })
            .catch((error) => {
                console.error('Error fetching company data:', error);
            });
    }

    function handleInputChange(event, field) {
        setCompanyData({ ...companyData, [field]: event.target.value });
    }

    function createCompany() {
        fetch('https://invoiceapi.ahmed7.com/create/company', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            },
            body: JSON.stringify(companyData),
        })
            .then((response) => {
                if (response.ok) {
                    showAlert('Company created successfully');
                    fetchCompany();
                } else {
                    showAlert('Failed to create company');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <Paper sx={{ p: 2, maxWidth: 600, mx: 'auto' }}>
            {alertComponent}
            <h2>Company Details</h2>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField label='Name' variant='outlined' fullWidth value={companyData.name} onChange={(e) => handleInputChange(e, 'name')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label='Address Line 1'
                        variant='outlined'
                        fullWidth
                        value={companyData.address1}
                        onChange={(e) => handleInputChange(e, 'address1')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label='Address Line 2'
                        variant='outlined'
                        fullWidth
                        value={companyData.address2}
                        onChange={(e) => handleInputChange(e, 'address2')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField label='Tel' variant='outlined' fullWidth value={companyData.tel} onChange={(e) => handleInputChange(e, 'tel')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField label='Fax' variant='outlined' fullWidth value={companyData.fax} onChange={(e) => handleInputChange(e, 'fax')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField label='GST' variant='outlined' fullWidth value={companyData.gst} onChange={(e) => handleInputChange(e, 'gst')} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label='Bank Details'
                        variant='outlined'
                        fullWidth
                        multiline
                        rows={14}
                        value={companyData.bank_details}
                        onChange={(e) => handleInputChange(e, 'bank_details')}
                    />
                </Grid>
            </Grid>
            <Button variant='contained' color='primary' onClick={createCompany}>
                Save
            </Button>
        </Paper>
    );
}
